<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('modcForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="modcForm" :model="modcForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="原合同编号" prop="modr_no">
            <el-input disabled v-model="modcForm.modr_no" maxlength="10" show-word-limit placeholder="请填写原合同编号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="修改次数" prop="modc_num">
            <el-input
              v-model="modcForm.modc_num"
              @input="modcForm.modc_num = helper.keepTNum(modcForm.modc_num)"
              maxlength="10"
              show-word-limit
              placeholder="请填写修改次数"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购所属部门" prop="modr_dept_id">
            <el-select v-model="modcForm.modr_dept_id" size="small" placeholder="请选择采购所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="modr_stff_id">
            <el-select v-model="modcForm.modr_stff_id" placeholder="请选择采购经办人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input disabled v-model="modcForm.supp_abbr" maxlength="10" show-word-limit placeholder="请填写供应商简称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销所属部门" prop="cust_dept_id">
            <el-select v-model="modcForm.cust_dept_id" size="small" placeholder="请选择外销所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="外销经办人" prop="cust_stff_id">
            <el-select v-model="modcForm.cust_stff_id" placeholder="请选择外销经办人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="变更日期:" prop="modc_date">
            <el-date-picker v-model="modcForm.modc_date" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="原送货地址" prop="modr_address">
            <el-input v-model="modcForm.modr_address" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请填写原送货地址" type="textarea"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="新送货地址" prop="new_modr_address">
            <el-input v-model="modcForm.new_modr_address" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请填写新送货地址" type="textarea"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small">导入</el-button>
            <el-button type="danger" plain size="small">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <!-- <ProdDetail :modcForm="modcForm"></ProdDetail> -->
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="原文" prop="modc_no">
            <el-input v-model="modcForm.modc_no" :autosize="{ minRows: 3, maxRows: 6 }" maxlength="10" placeholder="请填写原文" show-word-limit type="textarea">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="现改为" prop="modc_no">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="modcForm.modc_no"
              maxlength="10"
              show-word-limit
              placeholder="请填写现改为内容"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { modcAPI } from '@api/modules/modc';
import { deptAPI } from '@api/modules/department';
import { stffAPI } from '@/api/modules/staff';
import helper from '@assets/js/helper';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import UrlEncode from '@assets/js/UrlEncode';
// import ProdDetail from "./Component/MtrbDetail.vue";

export default {
  name: 'ModcAddMain',
  components: {
    addHeader,
    inputUser
    // ProdDetail,
  },
  data() {
    return {
      rules: {},
      modcForm: {
        modr_no: null,
        modc_num: null,
        modr_dept_id: null,
        modr_stff_id: null,
        supp_abbr: null,
        cust_dept_id: null,
        cust_stff_id: null,
        modc_date: null,
        mtrb_name: null,
        modc_item: null,
        prod_part_list: []
      },
      stffList: [],
      deptList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDept();
      this.getStffUser();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let modcForm = JSON.parse(JSON.stringify(this.modcForm));
      modcForm = Object.assign(modcForm, staffForm);
      post(modcAPI.addModc, modcForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/modc_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('modcForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('modcForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
